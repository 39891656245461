import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  faBell,
  faCheckDouble,
  faComments,
  faKey,
  faQuestion,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashboardLogo, UserLogo } from "../assets/images/Index";
import {
  Row,
  Col,
  Popover,
  Overlay,
  Dropdown,
  DropdownButton,
  Button,
} from "react-bootstrap";
import UserDetails from "../services/storage/UserDetails";
import DraftAppRit from "./DraftAppRit";
import SubmittedApplications from "../screens/SubmittedApplications";
import DashBoardService from "../services/service/DashBoardService";
import ErrorHandler from "../services/constant/ErrorHandler";
import CE from "./DashBoardList/CE";
import CommissionerApproved from "./DashBoardList/CommissionerApproved";
import ScrutinyReportGenerated from "./DashBoardList/ScrutinyReportGenerated";
import CommissionerShortfall from "./DashBoardList/CommissionerShortfall";
import DemandNoteGenerated from "./DashBoardList/DemandNoteGenerated";
import ZonalManagerApproved from "./DashBoardList/ZonalManagerApproved";
import ZonalManagerShortfall from "./DashBoardList/ZonalManagerShortfall";
import LAC from "./DashBoardList/LAC";
import DGM from "./DashBoardList/DGM";
import MSB from "./DashBoardList/MSB";
import DemandNoteIssued from "./DashBoardList/DemandNoteIssued";
import FinalPaymentCompleted from "./DashBoardList/FinalPaymentCompleted";
import SanctionedFiles from "./DashBoardList/SanctionedFiles";
import RejectedFiles from "./DashBoardList/RejectedFiles";
import FinalPaymentRejected from "./DashBoardList/FinalPaymentRejected";
import DigitalSignatureLatter from "./DashBoardList/DigitalSignatureLatter";
import CE_FILES from "./DashBoardList/CE_file";
import OTHERFILES from "./DashBoardList/OTHERFILES";
import MOMMEETINGGENERATED from "./DashBoardList/momMettingGenerated";
import DraftAppRitOccupancy from "./DraftAppRitOccupancy";
import SubmittedApplicationsOccupancy from "../screens/ApplicOccupancy/SubmittedApplicationsOccupancy";
import CommissionerApprovedOccupancy from "./DashBoardList/CommissionerApprovedOccupancy";
import CommissionerShortfallOccupancy from "./DashBoardList/CommissionerShortfallOccupancy";
import ZonalManagerApprovedOccupancy from "./DashBoardList/ZonalManagerApprovedOccupancy";
import DemandNoteIssuedOccupancy from "./DashBoardList/DemandNoteIssuedOccupancy";
import ZonalManagerShortfallOccupancy from "./DashBoardList/ZonalManagerShortfallOccupancy";
import ApplicationResubmitedOccupancy from "./DashBoardList/ApplicationResubmitedOccupancy";
import DemandNoteGeneratedOccupancy from "./DashBoardList/DemandNoteGeneratedOccupancy";
import RejectedFilesOccupancy from "./DashBoardList/RejectedFilesOccupancy";
import OTHERFILESOccupancy from "./DashBoardList/OTHERFILESOccupancy";
import RejectedFilesOccupancyCertificate from "./DashBoardList/RejectedFilesOccupancyCertificate";
import OccupancyDigitalSignatureLatter from "./DashBoardList/OccupancyDigitalSignatureLatter";
import OccupancyPaymentReciept from "./OccupancyPaymentReciept";
const DashboardLink = () => {
  const [dashBoard, setDashBoard] = useState({});
  const [dash, setDash] = useState(1);
  const selectFor = localStorage.getItem("selectFor");

  const getDashboard = () => {
    if (selectFor == 1) {
      DashBoardService.get_dashboard()
        .then((result) => {
          setDashBoard(result?.data);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }

    if (selectFor == 2) {
      DashBoardService.get_dashboard_occupancy()
        .then((result) => {
          setDashBoard(result?.data);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <div>
      {dashBoard && (
        <div>
          {selectFor == 1 && (
            <>
              <div className="px-3 py-0">
                <div className="dashBox  d-grid m-auto">
                  <Link
                    className="text-white w-100 p-2 d-grid m-auto text-center"
                    to={"#"}
                  >
                    <h5 className="w-100 m-auto">Total Files</h5>
                    <h2 className="w-100 m-auto">{dashBoard.total_count}</h2>
                  </Link>
                </div>
              </div>

              <div className="d-flex flex-wrap px-3 py-1">
                <div
                  onClick={() => setDash(1)}
                  className="dashBox d-flex grad-dash2"
                >
                  <div
                    activeClassName="ative-class"
                    className="text-white w-100 d-grid p-2 m-auto text-center"
                  >
                    <h5 className="w-100 mb-0">Draft Application</h5>
                    <h2 className="w-100 mb-0">{dashBoard.draft_count}</h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(2)}
                  className="dashBox d-flex grad-dash3"
                >
                  <div
                    activeClassName="ative-class"
                    className="text-white w-100 d-grid p-2 m-auto text-center"
                  >
                    <h5 className="w-100 mb-0">Submitted Application</h5>
                    <h2 className="w-100 mb-0">{dashBoard.submitted_count}</h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(3)}
                  className="dashBox d-flex grad-dash4"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Scrutiny Report
                      <br /> Generated
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.report_generated_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(4)}
                  className="dashBox d-flex grad-dash5"
                >
                  <div
                    activeClassName="ative-class"
                    className="text-white w-100 d-grid p-2 m-auto text-center"
                  >
                    <h5 className="w-100 mb-0">
                      Commissioner
                      <br /> Approved
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.commissioner_approved_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(5)}
                  className="dashBox d-flex grad-dash6"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Commissioner
                      <br /> Shortfall
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.commissioner_shortfall_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(6)}
                  className="dashBox d-flex grad-dash7"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Demand Note
                      <br /> Generated
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.demand_note_generated_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(7)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Zonal Manager
                      <br /> Approved
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.zm_approved_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(8)}
                  className="dashBox d-flex grad-dash10"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Zonal Manager
                      <br /> Shortfall
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.zm_shortfall_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(9)}
                  className="dashBox d-flex grad-dash9"
                >
                  <div className="text-white w-100 d-grid m-auto text-center p-2">
                    <h5 className="w-100 mb-0">
                      LAC(HO) <br /> (for High Rise)
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.lacho_applications}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(10)}
                  className="dashBox d-flex grad-dash2"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      DGM
                      <br /> (for High Rise)
                    </h5>
                    <h2 className="w-100 mb-0">{dashBoard.dgm_applications}</h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(19)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      CE <br /> (For High Rise)
                    </h5>
                    <h2 className="w-100 mb-0">{dashBoard.ce_applications}</h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(11)}
                  className="dashBox d-flex grad-dash3"
                >
                  <div className="text-white w-100 d-grid p-2  m-auto text-center">
                    <h5 className="w-100 mb-0">
                      MSB/MOM Meeting
                      <br /> (for High Rise)
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.lacho_mom_meeting}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(12)}
                  className="dashBox d-flex grad-dash4"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Demand Note
                      <br /> Issued
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.demand_note_issued_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(13)}
                  className="dashBox d-flex grad-dash5"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Final Payment
                      <br /> Completed
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.final_payment_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(17)}
                  className="dashBox d-flex grad-dash5"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Final Payment
                      <br /> Rejected
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.upload_documents_resubmitted_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(14)}
                  className="dashBox d-flex grad-dash6"
                >
                  <div className="text-white w-100 d-grid m-auto text-center p-2">
                    <h5 className="w-100 mb-0">
                      CE
                      <br />
                      Technical Approval
                      <br /> (for High Rise)
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.ce_technical_approved}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(15)}
                  className="dashBox d-flex grad-dash7"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Sanctioned Files</h5>
                    <h2 className="w-100 mb-0">{dashBoard.sanctioned_count}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(18)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Approved Sanction Files</h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard.digital_signature_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(20)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Staff Processing</h5>
                    <h2 className="w-100 mb-0">{dashBoard.other_files}</h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(16)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Rejected Files</h5>
                    <h2 className="w-100 mb-0">{dashBoard.rejected_count}</h2>
                  </div>
                </div>
                {/* <div
                  onClick={() => setDash(21)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Draft</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(22)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Total</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(23)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Submitted</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(24)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Status</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(25)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Occupancy Shortfall</h5>
                    <h2 className="w-100 mb-0">{"0"}</h2>
                  </div>
                </div> */}
              </div>
            </>
          )}

          {selectFor == 2 && (
            <>
              <div className="px-3 py-0">
                <div className="dashBox  d-grid m-auto">
                  <Link
                    className="text-white w-100 p-2 d-grid m-auto text-center"
                    to={"#"}
                  >
                    <h5 className="w-100 m-auto">Total Files</h5>
                    <h2 className="w-100 m-auto">{dashBoard?.total_count}</h2>
                  </Link>
                </div>
              </div>
              <div className="d-flex flex-wrap px-3 py-1">
                <div
                  onClick={() => setDash(1)}
                  className="dashBox d-flex grad-dash2"
                >
                  <div
                    activeClassName="ative-class"
                    className="text-white w-100 d-grid p-2 m-auto text-center"
                  >
                    <h5 className="w-100 mb-0">Draft Application</h5>
                    <h2 className="w-100 mb-0">{dashBoard?.draft_count}</h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(2)}
                  className="dashBox d-flex grad-dash3"
                >
                  <div
                    activeClassName="ative-class"
                    className="text-white w-100 d-grid p-2 m-auto text-center"
                  >
                    <h5 className="w-100 mb-0">Submitted Application</h5>
                    <h2 className="w-100 mb-0">{dashBoard?.submitted_count}</h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(3)}
                  className="dashBox d-flex grad-dash5"
                >
                  <div
                    activeClassName="ative-class"
                    className="text-white w-100 d-grid p-2 m-auto text-center"
                  >
                    <h5 className="w-100 mb-0">
                      Application
                      <br /> ReSubmitted
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.application_resubmitted_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(4)}
                  className="dashBox d-flex grad-dash5"
                >
                  <div
                    activeClassName="ative-class"
                    className="text-white w-100 d-grid p-2 m-auto text-center"
                  >
                    <h5 className="w-100 mb-0">
                      Commissioner
                      <br /> Approved
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.commissioner_approved_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(5)}
                  className="dashBox d-flex grad-dash6"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Commissioner
                      <br /> Shortfall
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.commissioner_shortfall_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(6)}
                  className="dashBox d-flex grad-dash8"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Zonal Manager
                      <br /> Approved
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.zm_approved_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(7)}
                  className="dashBox d-flex grad-dash10"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Zonal Manager
                      <br /> Shortfall
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.commissioner_shortfall_issued_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(8)}
                  className="dashBox d-flex grad-dash10"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Demand Note <br /> Generated
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.demand_note_generated_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(18)}
                  className="dashBox d-flex grad-dash10"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Fee Intimation <br />
                      Letter
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.demand_note_digital_signature_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(19)}
                  className="dashBox d-flex grad-dash10"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Final Payment
                      <br /> Completed
                    </h5>
                    <h2 className="w-100 mb-0">
                      {" "}
                      {dashBoard?.demand_note_fees_paid_count}
                    </h2>
                  </div>
                </div>

                <div
                  onClick={() => setDash(17)}
                  className="dashBox d-flex grad-dash10"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Approved Files</h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.occupancy_certificate_issued_count}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(15)}
                  className="dashBox d-flex grad-dash10"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">Rejection Files</h5>
                    <h2 className="w-100 mb-0">
                      {
                        dashBoard?.occupancy_certificate_rejection_signature_count
                      }
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => setDash(16)}
                  className="dashBox d-flex grad-dash10"
                >
                  <div className="text-white w-100 d-grid p-2 m-auto text-center">
                    <h5 className="w-100 mb-0">
                      Staff Processing <br />
                      Files
                    </h5>
                    <h2 className="w-100 mb-0">
                      {dashBoard?.staff_processing_count}
                    </h2>
                  </div>
                </div>
              </div>
            </>
          )}
          {selectFor == 1 && dash == 1 ? (
            <DraftAppRit />
          ) : selectFor == 1 && dash == 2 ? (
            <SubmittedApplications />
          ) : selectFor == 1 && dash == 3 ? (
            <ScrutinyReportGenerated />
          ) : selectFor == 1 && dash == 4 ? (
            <CommissionerApproved />
          ) : selectFor == 1 && dash == 5 ? (
            <CommissionerShortfall />
          ) : selectFor == 1 && dash == 6 ? (
            <DemandNoteGenerated />
          ) : selectFor == 1 && dash == 7 ? (
            <ZonalManagerApproved />
          ) : selectFor == 1 && dash == 8 ? (
            <ZonalManagerShortfall />
          ) : selectFor == 1 && dash == 9 ? (
            <LAC />
          ) : selectFor == 1 && dash == 10 ? (
            <DGM />
          ) : selectFor == 1 && dash == 11 ? (
            <MSB />
          ) : selectFor == 1 && dash == 12 ? (
            <DemandNoteIssued />
          ) : selectFor == 1 && dash == 13 ? (
            <FinalPaymentCompleted />
          ) : selectFor == 1 && dash == 14 ? (
            <CE />
          ) : selectFor == 1 && dash == 15 ? (
            <SanctionedFiles />
          ) : selectFor == 1 && dash == 16 ? (
            <RejectedFiles />
          ) : selectFor == 1 && dash == 17 ? (
            <FinalPaymentRejected />
          ) : selectFor == 1 && dash == 18 ? (
            <DigitalSignatureLatter />
          ) : selectFor == 1 && dash == 19 ? (
            <CE_FILES />
          ) : selectFor == 1 && dash == 20 ? (
            <OTHERFILES />
          ) : null}

          {selectFor == 2 && dash == 1 ? (
            <DraftAppRitOccupancy />
          ) : selectFor == 2 && dash == 2 ? (
            <SubmittedApplicationsOccupancy />
          ) : selectFor == 2 && dash == 3 ? (
            <ApplicationResubmitedOccupancy />
          ) : selectFor == 2 && dash == 4 ? (
            <CommissionerApprovedOccupancy />
          ) : selectFor == 2 && dash == 5 ? (
            <CommissionerShortfallOccupancy />
          ) : selectFor == 2 && dash == 6 ? (
            <ZonalManagerApprovedOccupancy />
          ) : selectFor == 2 && dash == 7 ? (
            <ZonalManagerShortfallOccupancy />
          ) : selectFor == 2 && dash == 8 ? (
            <DemandNoteGeneratedOccupancy />
          ) : selectFor == 2 && dash == 9 ? (
            // <ScrutinyReportGenerated />
            <ZonalManagerShortfallOccupancy />
          ) : selectFor == 2 && dash == 10 ? (
            // <DGM />
            <ZonalManagerShortfallOccupancy />
          ) : selectFor == 2 && dash == 11 ? (
            // <MSB />
            <ZonalManagerShortfallOccupancy />
          ) : selectFor == 2 && dash == 12 ? (
            <DemandNoteIssuedOccupancy />
          ) : selectFor == 2 && dash == 13 ? (
            // <FinalPaymentCompleted />
            // <SanctionedFiles />
            <ZonalManagerShortfallOccupancy />
          ) : selectFor == 2 && dash == 14 ? (
            // <CE />
            <ZonalManagerShortfallOccupancy />
          ) : selectFor == 2 && dash == 15 ? (
            <RejectedFilesOccupancy />
          ) : selectFor == 2 && dash == 16 ? (
            <OTHERFILESOccupancy />
          ) : selectFor == 2 && dash == 17 ? (
            // <FinalPaymentRejected />
            <RejectedFilesOccupancyCertificate />
          ) : selectFor == 2 && dash == 18 ? (
            <OccupancyDigitalSignatureLatter />
          ) : selectFor == 2 && dash == 19 ? (
            // <CE_FILES />
            <OccupancyPaymentReciept />
          ) : selectFor == 2 && dash == 20 ? (
            // <RejectedFiles />
            <ZonalManagerShortfallOccupancy />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DashboardLink;
