import logo from "../images/logo.png";
import logo1 from "../images/logo1.png";
import dpms from "../images/1.jpg";
import banner from "../images/banner.jpg";
import ltpreg from "../images/ltp_registration.png";
import citreg from "../images/citizen_registation.png";
import arrowLeft from "../images/arrow.png";
import application_btn from "../images/application_btn.png";
import online_payment from "../images/online_payment.png";
import newg from "../images/new.gif";
import footer_back from "../images/footer-backimg.png";
import footer_logo from "../images/footer_logo.png";
import document from "../images/document.png";
import password from "../images/password.png";
import lock from "../images/login_lock.png";
import auto_dcr_logo from "../images/AutoDCRLogo.png";
import user_logo from "../images/userLogo.png";
import no_image from "../images/noimage.jpg";
import trashBin from "../images/bin.png";

export const logoImage = logo;
export const userManualdownloadOcPDF = require("./Final User Manual_14.10.2024.pdf");

export const bin = trashBin;

export const Logo = () => (
  <img className="img-fluid ps-2" src={logo} alt="logo" />
);
export const DashboardLogo = () => (
  <img className="dash-logo" src={logo} alt="logo" />
);
export const Logo1 = () => <img className="logo" src={logo1} alt="logo" />;
export const DPMS = () => <img className="img-fluid" src={dpms} alt="dpms" />;
export const Banner = () => (
  <img className="bannerImg" src={banner} alt="banner" />
);
export const LOCK = () => (
  <img className="img-fluid " src={lock} alt="Login-Lock" />
);

export const LTP = () => (
  <img className="ltpcit d-none d-sm-block" src={ltpreg} alt="ltpreg" />
);
export const CIT = () => (
  <img className="ltpcit d-none d-sm-block" src={citreg} alt="citreg" />
);

export const Arrow = () => (
  <img className="arrow-reg" src={arrowLeft} alt="arrow-left" />
);

export const AppliBtn = () => (
  <img
    className="img-fluid mt-2 mt-sm-0"
    style={{ cursor: "pointer" }}
    src={application_btn}
    alt="application_btn"
  />
);

export const OnlinePay = () => (
  <img
    className="img-fluid"
    style={{ cursor: "pointer" }}
    src={online_payment}
    alt="online_payment"
  />
);

export const Newgif = () => <img className="newgif" src={newg} alt="newg" />;

export const FooterBack = () => (
  <img className="footer_back" src={footer_back} alt="footer-back" />
);

export const FooterLogo = () => (
  <img className="img-fluid" src={footer_logo} alt="footer_logo" />
);

export const DashboardDocument = () => (
  <img className="das_document" src={document} alt="das_document" />
);

export const DashboardPassword = () => (
  <img className="das_document" src={password} alt="das_password" />
);

export const AutoDcrLogo = () => (
  <img className="img-fluid" src={auto_dcr_logo} alt="auto_dcr_logo" />
);

export const UserLogo = () => (
  <img
    className="img-fluid my-2"
    style={{ borderRadius: "50%" }}
    src={user_logo}
    alt="user_logo"
  />
);

export const NoImage = () => (
  <img className="img-fluid mb-2" src={no_image} alt="no_image" />
);
