import React from "react";
import { userManualdownloadOcPDF } from "../assets/images/Index";
import ErrorHandler from "../services/constant/ErrorHandler";
import DashBoardService from "../services/service/DashBoardService";
import useToken from "../services/storage/useToken";

// const AuthVerif = () =>{
//   return()
// }

const RitFixed = () => {
  const { token, setToken } = useToken();
  const userManualdownload = () => {
    if (token) {
      DashBoardService.get_download_link()
        .then((result) => {
          let response = result.data;
          window.location.href = response.user_manual_pdf;
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please enter your username and password");
    }
  };
  const toolsdownload = () => {
    if (token) {
      DashBoardService.get_download_link()
        .then((result) => {
          let response = result.data;
          window.location.href = response.autocad_drawing;
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please enter your username and password");
    }
  };
  const gomdownload = () => {
    if (token) {
      DashBoardService.get_download_link()
        .then((result) => {
          let response = result.data;
          window.location.href = response.amendment_rar;
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please enter your username and password");
    }
  };

  const userManualdownloadOc = async (url) => {
    if (token) {
      DashBoardService.get_download_link()
        .then((result) => {
          let response = result.data;
          window.location.href = response.user_manual_oc_pdf;
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please enter your username and password");
    }
  };

  return (
    <div className="fixedRitLogin">
      <div className="">
        <button className="use-men" onClick={userManualdownload}>
          <a target="_blank">User Manual BP</a>
        </button>
        <button className="use-men-oc" onClick={userManualdownloadOc}>
          <a target="_blank">User Manual OC</a>
        </button>
        <button className="tl-men" onClick={toolsdownload}>
          Tools
        </button>
        <button className="gm-men" onClick={gomdownload}>
          GOM
        </button>
      </div>
    </div>
  );
};

export default RitFixed;
