import React, { useEffect, useState } from "react";
import {
  faFile,
  faFileAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "react-switch";
import {
  Accordion,
  Button,
  Col,
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Row,
} from "react-bootstrap";
import ApplicationService from "../../services/service/ApplictionService";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";

const ApplyNOC = (props) => {
  const [update, setUpdate] = useState(false);
  const [togglebtnround, setTogglebtnround] = useState(false);
  const [textClickHandle, setTextClickHandle] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [fireFileURL, setFireFileURL] = useState("");
  const [fireFile2URL, setFireFile2URL] = useState("");
  const [fireFile3URL, setFireFile3URL] = useState("");
  const [fireFile4URL, setFireFile4URL] = useState("");

  const [files, setFiles] = useState([
    {
      type: 1,
      noc_file: "",
      preview: "",
    },
    {
      type: 2,
      noc_file: "",
      preview: "",
    },
    {
      type: 3,
      noc_file: "",
      preview: "",
    },
    {
      type: 4,
      noc_file: "",
      preview: "",
    },
  ]);
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    ApplicationService.viewApplication({
      user_application_id: props.data.id,
      type: 6,
    })
      .then((result) => {
        let response = result.data.user_application_noc;
        if (response.length > 0) {
          setUpdate(true);
          let temp = [...files];
          response.map((item, index) => {
            if (item.type == 1) {
              const index = temp.findIndex((fruit) => fruit.type == 1);
              temp[index] = {
                user_application_noc_id: item.id,
                type: 1,
                noc_file: "",
                preview: "",
              };
              setFireFileURL(item.noc_file);
            } else if (item.type == 2) {
              const index = temp.findIndex((fruit) => fruit.type == 2);
              temp[index] = {
                user_application_noc_id: item.id,
                type: 2,
                noc_file: "",
                preview: "",
              };
              setFireFile2URL(item.noc_file);
            } else if (item.type == 3) {
              const index = temp.findIndex((fruit) => fruit.type == 3);
              temp[index] = {
                user_application_noc_id: item.id,
                type: 3,
                noc_file: "",
                preview: "",
              };
              setFireFile3URL(item.noc_file);
            } else if (item.type == 4) {
              const index = temp.findIndex((fruit) => fruit.type == 4);
              temp[index] = {
                user_application_noc_id: item.id,
                type: 4,
                noc_file: "",
                preview: "",
              };
              setFireFile4URL(item.noc_file);
            }
          });
          setFiles(temp);
        }
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const handleClick = (type) => {
    if (type == 1) {
      setModalData(fireFileURL);
    } else if (type == 2) {
      setModalData(fireFile2URL);
    } else if (type == 3) {
      setModalData(fireFile3URL);
    } else if (type == 4) {
      setModalData(fireFile4URL);
    }
    setModal(true);
  };

  const handleChange = () => {
    setTogglebtnround(!togglebtnround);
    setTextClickHandle(!textClickHandle);
  };
  const clickTaggleHandle = () => {
    setTextClickHandle(!textClickHandle);
  };

  const [modalOneshow, setModalOneShow] = useState(false);
  const [modalTwoshow, setModalTwoShow] = useState(false);

  const modalOneClose = () => setModalOneShow(false);
  const modalOneShow = () => setModalOneShow(true);

  const modalTwoClose = () => setModalTwoShow(false);
  const modalTwoShow = () => setModalTwoShow(true);

  const [fireFile, setFireFile] = useState("");
  const [fireFile2, setFireFile2] = useState("");
  const [fireFile3, setFireFile3] = useState("");
  const changeFile = (e, type) => {
    if (e.target.files[0].size < 26214400) {
      if (update) {
        let temp = [...files];
        const index = temp.findIndex((fruit) => fruit.type == type);
        temp[index].noc_file = e.target.files[0];
        temp[index].preview = URL.createObjectURL(e.target.files[0]);
        setFiles(temp);
        if (type == 1) {
          setFireFileURL("");
        } else if (type == 2) {
          setFireFile2URL("");
        } else if (type == 3) {
          setFireFile3URL("");
        } else if (type == 4) {
          setFireFile4URL("");
        }
      } else {
        let temp = [...files];
        const index = temp.findIndex((fruit) => fruit.type == type);
        temp[index].noc_file = e.target.files[0];
        temp[index].preview = URL.createObjectURL(e.target.files[0]);
        setFiles(temp);
        // setFireFile(e.target.files[0]);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeFile2 = (e) => {
    if (e.target.files[0].size < 26214400) {
      if (update) {
        let temp = [...files];
        const index = temp.findIndex((fruit) => fruit.type == 2);
        temp[index].noc_file = e.target.files[0];
        setFireFile2URL("");
        setFiles(temp);
      } else {
        setFireFile2(e.target.files[0]);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeFile3 = (e) => {
    if (e.target.files[0].size < 26214400) {
      if (update) {
        let temp = [...files];
        const index = temp.findIndex((fruit) => fruit.type == 3);
        temp[index].noc_file = e.target.files[0];
        setFireFile3URL("");
        setFiles(temp);
      } else {
        setFireFile3(e.target.files[0]);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeFile4 = (e) => {
    if (e.target.files[0].size < 26214400) {
      if (update) {
        let temp = [...files];
        const index = temp.findIndex((fruit) => fruit.type == 4);
        temp[index].noc_file = e.target.files[0];
        setFireFile4URL("");
        setFiles(temp);
      } else {
        setFireFile3(e.target.files[0]);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const handlePreview = (index) => {
    setModalData(files[index].preview);
    setModal(true);
  };
  const save = () => {
    if (update) {
      let count = 1;
      let payload = [];
      files.map((item, index) => {
        if (item.noc_file != "") {
          payload.push(item);
          count = count + 1;
        }
      });
      if (count == 1) {
        alert("Please change file to update");
      } else {
        var form_data = new FormData();
        form_data.append("user_application_id", props.data.id);
        payload.map((item, index) => {
          if (item.user_application_noc_id) {
            form_data.append(
              `nocs[${index}][user_application_noc_id]`,
              item.user_application_noc_id
            );
          }
          form_data.append(`nocs[${index}][type]`, item.type);
          form_data.append(`nocs[${index}][noc_file]`, item.noc_file);
        });
        ApplicationUpdateService.updateNOC(form_data)
          .then((result) => {
            alert(result.data.message);
            getDetails();
            setFireFile("");
            setFireFile2("");
            setFireFile3("");
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      }
    } else {
      const index = files.findIndex((fruit) => fruit.type == 1);
      if (
        files[index].noc_file != "" &&
        typeof files[index].noc_file == "object"
      ) {
        var form_data = new FormData();
        form_data.append("user_application_id", props.data.id);
        let temp = [];
        files.map((item, index) => {
          if (item.noc_file != "" && typeof item.noc_file == "object") {
            temp.push(item);
          }
        });

        temp.map((item, index) => {
          form_data.append(`nocs[${index}][type]`, item.type);
          form_data.append(`nocs[${index}][noc_file]`, item.noc_file);
        });
        ApplicationService.saveApplyNOC(form_data)
          .then((result) => {
            alert(
              result.data.message
                ? result.data.message
                : "Application saved successfully"
            );
            getDetails();
            setFireFile("");
            setFireFile2("");
            setFireFile3("");
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      } else {
        alert("please fill all Details ?");
      }
    }
  };
  return (
    <>
      <Col className="prop-norm">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">Fire NOC </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {fireFileURL != "" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handleClick(1)}
                    >
                      View
                    </Button>
                  )}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 1)}
                    accept=".pdf,.PDF"
                  />
                  {files[0].preview != "" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(0)}
                    >
                      Preview
                    </Button>
                  )}
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">Environmental clearance </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {fireFile2URL != "" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handleClick(2)}
                    >
                      View
                    </Button>
                  )}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 2)}
                    accept=".pdf,.PDF"
                  />
                  {files[1].preview != "" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(1)}
                    >
                      Preview
                    </Button>
                  )}
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">Traffic NOC ( If Applicable ) </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {fireFile3URL != "" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handleClick(3)}
                    >
                      View
                    </Button>
                  )}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 3)}
                    accept=".pdf,.PDF"
                  />
                  {files[2].preview != "" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(2)}
                    >
                      Preview
                    </Button>
                  )}
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">
                Airport Authority of India( If Applicable){" "}
              </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {fireFile4URL != "" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handleClick(4)}
                    >
                      View
                    </Button>
                  )}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 4)}
                    accept=".pdf,.PDF"
                  />
                  {files[3].preview != "" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(3)}
                    >
                      Preview
                    </Button>
                  )}
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <Col>
          <p className="f14 mb-0 text-danger bg-lt-white p-2 mt-2">
            Yes - I do not have Applied NOC and want to obtain NOC from
            respective office (Online procedure).
          </p>
          <p className="f14 mb-0 text-danger bg-lt-white p-2 mt-2">
            No - I have Applied NOC.
          </p>
        </Col> */}
        <Col className="d-flex justify-content-center mb-2">
          <Button
            onClick={save}
            className="tab-blu-back"
            style={{
              color: "#fff",
              fontSize: 15,
              marginTop: 10,
              padding: "6px 14px",
              backgroundColor: "#5593db",
            }}
          >
            <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
            Save
          </Button>
        </Col>
      </Col>

      <Modal show={modalOneshow} onHide={modalOneClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="justify-content-center">
            <div className="f14 text-center">
              <strong>Fire NOC Condition</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="f14 text-start">
            <strong>Note :</strong>
          </div>
          <div className="f12 text-start">
            1 : All Types of Residential Buildings with height more than 18m. or
            Non Residential Buildings with height more than 15m.
          </div>
          <br />
          <div className="f12 text-start">
            2 : Public Congregation Places like Schools, Auditoriums , Function
            Halls. Height between 6m. to 15m. and area more than 500sq.m.
          </div>
          <div className="f14 text-start">
            <strong>For this application :</strong>
            Type of Building : Residential Building, Height : 30.5m.
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-secondary" onClick={modalOneClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalTwoshow} onHide={modalTwoClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="justify-content-center">
            <div className="f14 text-center">
              <strong>AAI NOC Condition</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="f14 text-start">
            <strong>Note :</strong>
            Whether proposed site falls whith in the Air funnel Zone? If
            selected Yes, the proposal requires NOC from AAI Department.
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-secondary" onClick={modalTwoClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {modal === true ? (
        <>
          <Modal
            show={modal}
            size="xl"
            onHide={() => {
              setModalData("");
              setModal(false);
            }}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                Image
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <iframe src={modalData} style={{ height: "500px" }}></iframe>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default ApplyNOC;
